// You can delete this file if you're not using it
import * as React from 'react'
import { ApolloProvider } from 'react-apollo'
import { client } from './../apollo/client'

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    {/* HELLO LANCE */}
    {element}
  </ApolloProvider>
)
