import ApolloClient from 'apollo-client'
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import fetch from 'isomorphic-fetch'
import { Auth } from 'aws-amplify'
import awsmobile from './../aws-exports'

//TODO: use aws-exports file for appsync url
// uri: awsmobile.aws_appsync_graphqlEndpoint,
// 'https://5qtkm55p7rfihlyzr2pu4wqtfm.appsync-api.us-east-1.amazonaws.com/graphql',

// export const client = new ApolloClient({
//   uri:
//     'https://5qtkm55p7rfihlyzr2pu4wqtfm.appsync-api.us-east-1.amazonaws.com/graphql',
//   fetch,
// })

// ApolloClient  Initialization
const httpLink = createHttpLink({
  uri: awsmobile.aws_appsync_graphqlEndpoint,
})

const authLink = setContext(
  request =>
    new Promise((resolve, reject) => {
      Auth.currentSession().then(session => {
        const token = session.idToken.jwtToken
        resolve({
          headers: { Authorization: token },
        })
      })
    })
)

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  fetch,
})
