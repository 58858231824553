// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:12da0f1f-0dc6-4d0f-8109-5a67959300ae",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_vKiDleNeo",
    "aws_user_pools_web_client_id": "64g7rdafp1ib6au09kt80agsrq",
    "aws_cloud_logic_custom": [
        {
            "name": "bookingsLambda",
            "endpoint": "https://h7s9zahmr2.execute-api.us-east-1.amazonaws.com/seadream",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://relp3x6xonajtlxyvll753xxfy.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_user_files_s3_bucket": "seadream-s3object-seadream",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
